import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Countdown from "../components/Landing/CountDown";
import { ItemsAction, withItems } from "../hoc/withItems";
import { PrizeAction } from "../hoc/withPize";
import { Item, ItemsState } from "../redux/slices/itemsSlice";
import { toast } from "react-toastify";
import { Spinner } from "../components/Spinner/Spinner";
import {
    ITEM_TYPE_FOLDER,
    ITEM_TYPE_FOODMARKT_OPENING,
    ITEM_TYPE_JUMBO,
    ITEM_TYPE_MOBILE_PRACTICE,
    ITEM_TYPE_SPIN_N_WIN,
    ITEM_TYPE_WEEK_DEAL,
    ROUTE_FOLDER,
    ROUTE_LANDING,
    ROUTE_MOBILE_PRACTICE,
    ROUTE_ROOT_PAGE,
    ROUTE_SPIN_DISCOUNT,
    ROUTE_SPIN_N_WIN,
    ROUTE_SUNDAY_OFFER,
    ROUTE_WEEK_DEAL, USER_CAN_REDEEM_CODE, USER_CAN_SPIN, USER_CAN_SPIN_TODAY, USER_SPIN_DISABLE,
} from "../helpers/Constants";
import { UserState } from "../redux/slices/userSlice";
import { LocalStorage } from "../helpers/LocalStorage";
import { Modal, ModalBody } from "react-bootstrap";
const videoSources = [
    "Landing1.mp4",
    "Landing2.mp4",
    "Landing3.mp4",
    "Landing4.mp4",
    "Landing5.mp4",
    "Landing6.mp4",
    "Landing7.mp4",
    "Landing8.mp4",
    "Landing9.mp4",
    "Landing10.mp4",
];
const LandingPage = ({
    items,
    itemsLoading,
    error,
    user,
}: ItemsState & UserState) => {
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const [userName, setUserName] = useState("");
    const [hasLoggedInBefore, setHasLoggedInBefore] = useState(false);
    const index = Math.floor(Math.random() * videoSources.length);
    const videoFile = videoSources[index];
    const [showModal, setShowModal] = useState(false);
    const videoRef = useRef(null);

    const isDesktop = window.innerWidth >= 1200;
    const user_name = LocalStorage.getUser();

    useEffect(() => {
        if (error) {
            toast.error(error);
        }
    }, [error]);

    const onItemClick = (item: Item) => {
        if (item.active_status !== undefined && item.active_status === false)
            return;

        if (item.type === ITEM_TYPE_SPIN_N_WIN) {
            if (item.state === USER_CAN_SPIN || item.state === USER_CAN_SPIN_TODAY){
                navigate(ROUTE_SPIN_N_WIN, { replace: true });

            }else if (item.state === USER_CAN_REDEEM_CODE) {
                navigate(ROUTE_SPIN_DISCOUNT, { replace: true });

            }else if (item.state === USER_SPIN_DISABLE) {

            }
        } else if (item.type === ITEM_TYPE_FOODMARKT_OPENING) {
            setShowModal(true);
        } else if (
            item.type === ITEM_TYPE_WEEK_DEAL &&
            item.current_deal_reedemed !== true
        ) {
            navigate(ROUTE_WEEK_DEAL, { replace: true });
        } else if (item.type === ITEM_TYPE_FOLDER) {
            // navigate(ROUTE_FOLDER, { replace: true });
            window.open("https://www.jumbo.com/acties/weekaanbiedingen/", "_blank");
        } else if (item.type === ITEM_TYPE_JUMBO) {
            navigate(ROUTE_SUNDAY_OFFER, { replace: true });
        } else if (item.type === ITEM_TYPE_MOBILE_PRACTICE) {
            navigate(ROUTE_MOBILE_PRACTICE, { replace: true, state: item });
        } else if (item.type === ITEM_TYPE_FOODMARKT_OPENING) {
            navigate(ROUTE_LANDING, { replace: true });
        }
    };

    const getImageSrcMobile = (item: Item) => {
        return item.image_mobile;
    };

    const getImageSrcDesktop = (item: Item) => {
        return item.image_desktop;
    };

    const handleClick = () => {
        // navigate(ROUTE_ROOT_PAGE, { replace: true });
        if (window.is_intro === false) return;
        navigate(ROUTE_ROOT_PAGE, { replace: true });
    };

    const getItemLayout = (item: Item) => {
        if (item.type === ITEM_TYPE_FOODMARKT_OPENING) {
            return (
                <div
                    className="btn-div col-lg-6 col-md-6 col-sm-6 col-12"
                    onClick={() => onItemClick(item)}
                >
                    <div className="timer-div ">
                        <p className="timer-title">{item.title}</p>
                        <Countdown targetDate={item.meta.opening_date} />
                        <p className="smalle-content mobile-view-hide">
                            Site Decathlon & WEBA
                        </p>
                    </div>
                </div>
            );
        }
        if (
            item.type === ITEM_TYPE_WEEK_DEAL &&
            item.current_deal_reedemed === true &&
            item.start_time
        ) {
            return (
                <div
                    className="btn-div col-lg-6 col-md-6 col-sm-6 col-12"
                    onClick={() => onItemClick(item)}
                >
                    <div className="timer-div ">
                        <p className="timer-title">{item.meta.disable_title}</p>
                        <Countdown targetDate={item.start_time} />
                        {/* <p className="smalle-content mobile-view-hide">
                            VLIEGTUIGLAAN, 9000 GENT
                        </p> */}
                    </div>
                </div>
            );
        }
        return (
            <div
                className="btn-div col-lg-6 col-md-6 col-sm-6 col-12"
                onClick={() => onItemClick(item)}
            >
                <img
                    src={getImageSrcDesktop(item)}
                    alt=""
                    className="img-fluid card-img desktop-item"
                />
                <img
                    src={getImageSrcMobile(item)}
                    alt=""
                    className="card-img mobile-item"
                />
                <div className="content-welcm-content timer-card-width ">
                    <p className="text-white text-size">
                        {/* {item.image_mobile} */}
                    </p>
                    <p className="time-txt-white text-black text-size sml-size">
                        {/* {item.title === ITEM_TYPE_WEEK_DEAL &&
                                                                    <Countdown targetDate={item.prize_count_down} />
                                                                }  */}
                    </p>
                </div>
            </div>
        );
    };

    return (
        <section className="home-page-section bg-change landing-page">
            <div className="inner-section">
                <div className=" main-container for-flex-item">
                    <div className="welcom-head-sec text-center">
                        <div className="head-inner">
                            {user && user?.is_registered ? (
                                <h2 className="wel-head text-white">
                                    WELKOM TERUG
                                    <span className="txt-primary">
                                        &nbsp;{user_name?.first_name}
                                    </span>
                                </h2>
                            ) : (
                                <h2 className="wel-head text-white">
                                    WELKOM
                                    <span className="txt-primary">
                                        &nbsp;{user_name?.first_name}
                                    </span>
                                </h2>
                            )}
                            <div className="social-icon-div">
                                <p className="text-social">VOLG ONS</p>
                                <a
                                    href="https://www.facebook.com/profile.php?id=61556701174663"
                                    className="fab-icon"
                                    target="_blank"
                                >
                                    <img
                                        src="../front/assets/img/facebook.png"
                                        alt=""
                                        className="img-fluid fab-img"
                                    />
                                </a>
                                <a
                                    href="https://www.instagram.com/jumbofoodmarktgent/"
                                    className="fab-icon"
                                    target="_blank"
                                >
                                    <img
                                        src="../front/assets/img/insta.png"
                                        alt=""
                                        className="img-fluid insta-img"
                                    />
                                </a>
                            </div>
                        </div>
                        {isDesktop && (
                            <div className="landing-video mobile-view-hide">
                                <video
                                    className="video-tag landing-video-none"
                                    width="100%"
                                    height="100%"
                                    loop={false}
                                    autoPlay
                                    muted={true}
                                    controls={false}
                                >
                                    <source
                                        src={`/front/assets/LandingVideos/${videoFile}`}
                                        type="video/mp4"
                                    />
                                </video>
                                {/* <img
                                src="../front/assets/img/another-img9000.png"
                                alt=""
                                className="img-fluid video-tag"
                            /> */}
                            </div>
                        )}
                    </div>
                    <div className="middle-btn-sec row">
                        {itemsLoading ? (
                            <Spinner />
                        ) : (
                            items?.map((item) => (
                                <div key={item.id}>{getItemLayout(item)}</div>
                            ))
                        )}
                    </div>
                    <div className="bottom-text-sec landing-btm">
                        <>
                        <div
                            className="head-div d-flex justify-content-center align-items-center"
                            onClick={handleClick}
                        >
                            <div className="butm-jumb-img" >
                                <img
                                    className="jumbo-img-btm img-fluid"
                                    src="../front/assets/img/Group-487.svg"
                                    alt=""
                                />
                            </div>
                            <div className="butm-jumb-img food-mrkt-img" >
                                <img
                                    className="food-mrkt-img img-fluid"
                                    src="../front/assets/img/for-black-food.png"
                                    alt=""
                                />
                            </div>
                        </div>
                            <div className="buttom-new-tab">
                                <a
                                    href="../assets/Wedstrijdreglement_JUMBO_Draai_Win_Foodmarkt_Gent.pdf"
                                    className="new-page-tab text-black"
                                    target="_blank"
                                >
                                    <img src="../front/assets/img/white-i.png" alt="" className="modal-i-circle img-fluid"/>
                                </a>
                            </div>
                        </>
                    </div>
                </div>
            </div>
            <Modal
                show={showModal}
                centered
                size="lg"
                scrollable
                onHide={() => setShowModal(false)}
                className=""
            >
                <ModalBody className="">
                    <div className="container landing-popup-video">
                        <video
                            className="img-fluid modal-video position-relative"
                            ref={videoRef}
                            playsInline
                            webkit-playsinline="true"
                            preload="meta"
                            controls
                        >
                            <source
                                src="/front/assets/img/video6.mp4"
                                type="video/mp4"
                            />
                        </video>
                    </div>
                </ModalBody>
            </Modal>
        </section>
    );
};

export default withItems(LandingPage);
