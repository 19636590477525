import {
    Field,
    GuestCampClient,
    KeyValueContainer,
    Registration,
} from "@snayvik-tech-labs/guestcamp-api";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Utils } from "../helpers/Utils";
import { RegistrationUI } from "../components/RegistrationUI/RegistrationUI";
import { LocalStorage } from "../helpers/LocalStorage";
import { Spinner } from "../components/Spinner/Spinner";

import {
    API_URL,
    ROUTE_REGISTRATION,
    ROUTE_SPIN_N_WIN,
} from "../helpers/Constants";
import axios from "axios";
import OtpForm from "../components/RegistrationUI/OtpForm";
import { toast } from "react-toastify";

const FullRegistrationPage = () => {
    const [loading, setLoading] = useState(false);
    const [userDetail, setUserDetail] = useState<KeyValueContainer>({});
    const [otpForm, setOtpForm] = useState<boolean>(false);

    const navigate = useNavigate();
    const onRegistration = (registration: Registration | undefined) => {
        if (registration) {
            setLoading(false);
            // toast.success("user verified successfully");
            LocalStorage.setUser(registration);
            // console.log(registration);
            navigate(ROUTE_SPIN_N_WIN);
            // setUserDetail(registration);
            // setOtpForm(true);
        }
    };
    const { state } = useLocation();

    return (
        <section className="home-page-section bg-change email-page">
            <div className="inner-section desk-full-rg-inner-sec">
                <Link to={ROUTE_REGISTRATION}>
                    <img
                        src="../front/assets/img/back-btn.png"
                        alt=""
                        className="back-btn"
                    />
                </Link>
                <div className="email-section registration-sec">
                    <div className="desk-full-regs">
                        <div className="email-head-sec position-relative">
                            <h2 className="email-title">
                                WORD{" "}
                                <span className="txt-primary"> JUMBO FAN</span>
                            </h2>
                            <p className="comment-txt eml-txt">VREE WIJS</p>
                        </div>
                        <div className="email-input-btn-div full-input-div">
                            <>
                                {loading ? (
                                    <Spinner />
                                ) : (
                                    <RegistrationUI
                                        locale={"nl"}
                                        email={state?.email}
                                        onFieldsLoading={() =>
                                            console.info("onFieldsLoading")
                                        }
                                        onFieldRequestFail={(error) =>
                                            console.info(
                                                "onFieldRequestFail " + error
                                            )
                                        }
                                        onFieldRequestSuccess={() =>
                                            console.info(
                                                "onFieldRequestSuccess"
                                            )
                                        }
                                        onFieldsLoaded={() =>
                                            console.info("onFieldsLoaded")
                                        }
                                        onRegistrationFail={(error) => {
                                            console.info(
                                                "onRegistrationFail " + error
                                            );
                                        }}
                                        onRegistrationRequestStart={() => {
                                            console.info(
                                                "onRegistrationRequestStart"
                                            );
                                        }}
                                        onRegistrationSuccess={onRegistration}
                                    />
                                )}
                            </>
                        </div>
                    </div>
                </div>
                <div className="bottom-map-div form-map-div">
                    <div className="bottom-txt-div">
                        <img
                            src="../front/assets/img/boyImage.png"
                            alt=""
                            className="img-fluid map-image"
                        />
                        <p className="txt-botm text-light">
                            MAKE IT, TAKE IT, EAT IT
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FullRegistrationPage;
