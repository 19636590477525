import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UserStateModel, withUser } from "../hoc/withUser";
import { useOutletContext } from "react-router-dom";
import { EmailCheckState } from "../redux/slices/emailSlice";
import { Utils } from "../helpers/Utils";
import { useNavigate } from "react-router-dom";
import { Spinner } from "../components/Spinner/Spinner";
import {
    GuestCampClient,
    KeyValueContainer,
} from "@snayvik-tech-labs/guestcamp-api";
import { LocalStorage } from "../helpers/LocalStorage";
import { toast } from "react-toastify";
import {
    API_URL,
    ROUTE_FULL_REGISTRATION,
    ROUTE_SPIN_N_WIN,
} from "../helpers/Constants";
import { sendUserOtp } from "../hoc/sendUserOtp";
import axios from "axios";
import OtpForm from "../components/RegistrationUI/OtpForm";

const RegistrationPage = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState<string>("");
    const [isValidEmail, setIsValidEmail] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [otpForm, setOtpForm] = useState<boolean>(false);

    const [userDetail, setUserDetail] = useState<KeyValueContainer>({});
    const { checkEmail, mailCheckState, userState } =
        useOutletContext<UserStateModel>();

    const onEmailFieldChange = (e: ChangeEvent) => {
        const email = e.currentTarget.value;
        setEmail(email);
        if (Utils.isValidEmail(email)) {
            setIsValidEmail(true);
        } else {
            setIsValidEmail(false);
        }
    };

    const onStartClick = useCallback(
        (e: ChangeEvent) => {
            e.preventDefault();
            if (isValidEmail) {
                setLoading(true);
                GuestCampClient.GetUserDetails({
                    email,
                })
                    .then((mailRecip) => mailRecip.data)
                    .then((mailRecip) => {
                        const payload = { email: mailRecip?.email };
                        axios
                            .post(`${API_URL}user/detail`, payload)
                            .then((response) => response.data)
                            .then((userResponse) => {
                                // console.log(userResponse);
                                LocalStorage.setUser(userResponse.data);
                                navigate(ROUTE_SPIN_N_WIN);
                                setLoading(false);
                            })
                            .catch((error) => {
                                console.log(error);
                                toast.error(
                                    error.message ?? "Failed to send opt"
                                );
                                setLoading(false);
                            });
                        // navigate(ROUTE_SPIN_N_WIN);
                    })
                    .catch((error) => {
                        console.info({ error: error.response.data.message });
                        // toast.error(
                        //     error.response.data.message ?? "No Data Found"
                        // );
                        setLoading(false);
                        navigate(ROUTE_FULL_REGISTRATION, {
                            replace: true,
                            state: { email },
                        });
                    });
            }
        },
        [email]
    );

    const handleChange = (e: ChangeEvent) => {
        const otpValue = e.currentTarget.value;
        setOtp(otpValue);
    };

    return (
        <section className="home-page-section bg-change email-page">
            <div className="inner-section position-relative desk-email-view">
                <div className="email-section">
                    <div className="for-destop-width">
                        <div className="email-head-sec position-relative">
                            <h2 className="email-title">
                                WORD{" "}
                                <span className="txt-primary"> JUMBO FAN</span>
                            </h2>
                            <p className="comment-txt eml-txt">VREE WIJS</p>
                        </div>
                        <div className="email-input-btn-div">
                            <form action="">
                                <div className="email-div form-group">
                                    <label className="cl-lb" htmlFor="email">
                                        Email
                                    </label>
                                    <input
                                        onChange={onEmailFieldChange}
                                        type="email"
                                        id="email"
                                        className="form-control email input-field"
                                    />
                                </div>
                                {isValidEmail ? (
                                    <div className="email-btn-div">
                                        {
                                            <button
                                                onClick={onStartClick}
                                                className="start-btn df-line email-reg"
                                            >
                                                JUMBO{" "}
                                                <span className="txt-black">
                                                    START{" "}
                                                </span>{" "}
                                                {loading && <Spinner />}
                                            </button>
                                        }
                                    </div>
                                ) : (
                                    ""
                                )}
                            </form>
                        </div>
                    </div>
                </div>
                <div className="bottom-map-div email-end-sec">
                    <div className="bottom-txt-div">
                        {/* <div className="destop-head-log">
                            <img src="" alt="" />
                        </div> */}
                        <img
                            src="../front/assets/img/boyImage.png"
                            alt=""
                            className="img-fluid map-image"
                        />
                        <p className="txt-botm text-light">
                            MAKE IT, TAKE IT, EAT IT
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default RegistrationPage;
