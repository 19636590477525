import axios from "axios";
import React, { ChangeEvent, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { KeyValueContainer } from "@snayvik-tech-labs/guestcamp-api";
import {
    API_URL,
    ROUTE_LANDING,
    ROUTE_SPIN_DISCOUNT_JUST_WON,
    ROUTE_SPIN_N_WIN,
} from "../helpers/Constants";
import { LocalStorage } from "../helpers/LocalStorage";
import { Spinner } from "../components/Spinner/Spinner";

function OtpVerificationPage() {
    // const OtpVerificationPage = () => {}
    // const [userDetail, setUserDetail] = useState<KeyValueContainer>({});
    const { state } = useLocation();

    const [isLoading, setLoading] = useState<Boolean>(false);
    const [reSendLoding, setReSendLoding] = useState<Boolean>(false);
    const [otp, setOtp] = useState<Number>();
    const navigate = useNavigate();
    const user = LocalStorage.getUser();

    const handleChange = (e: ChangeEvent) => {
        const otpValue = e.currentTarget.value;
        setOtp(otpValue);
    };

    const sendOtpMail = () => {
        const payload = { email: user?.email };
        axios
            .post(`${API_URL}user/validate_2fa`, payload)
            .then((data) => {
                // console.log(data);
            })
            .catch((error) => {
                console.log(error);
            });
        // console.info({ state })
    };

    useEffect(() => {
        sendOtpMail();
    }, []);

    const reSendOtpMail = () => {
        setReSendLoding(true);
        sendOtpMail();

        setTimeout(() => {
            setReSendLoding(false);
        }, 1000);
    };

    // console.log(user.user.email);
    const onVerifyOtp = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const payload = { otp: otp, email: user?.email };
        // alert('email :  "'+payload?.email+'"  otp :  '+payload.otp);
        setLoading(true);
        axios
            .post(`${API_URL}user/validate_2fa/verify`, payload)
            .then((data) => {
                setLoading(false);
                // toast.success("user verified successfully");

                localStorage.setItem("userVarification", JSON.stringify(true));
                if (state.route_to_landing === true) {
                    navigate(ROUTE_LANDING, {
                        replace: true,
                        state: state,
                    });
                } else {
                    navigate(ROUTE_SPIN_DISCOUNT_JUST_WON, {
                        replace: true,
                        state: state,
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                toast.error(
                    error.response?.data?.message ??
                        "Invalid OTP, Please try again"
                );
                // console.log(error);
            });
    };

    return (
        <section className="home-page-section bg-change email-page">
            <div className="inner-section position-relative desk-email-view">
                <div className="email-section">
                    <div className="for-destop-width">
                        <div className="email-head-sec position-relative">
                            <h2 className="email-title">
                                WORD{" "}
                                <span className="txt-primary"> JUMBO FAN</span>
                            </h2>
                            <p className="comment-txt eml-txt">VREE WIJS</p>
                        </div>
                        <div className="email-input-btn-div">
                            <div className="otp-section">
                                <div className="otp-instruction">
                                    <p className="instruction txt-primary">
                                        Je hebt op het mailadres "{user?.email}"
                                        een email ontvangen met je éénmalige
                                        code. Geef hieronder de code in.
                                    </p>
                                </div>
                                <form action="" onSubmit={onVerifyOtp}>
                                    <div className="email-div form-group otp-input-div">
                                        <label className="cl-lb" htmlFor="otp">
                                            {" "}
                                            Code{" "}
                                        </label>
                                        <input
                                            onChange={handleChange}
                                            id="otp"
                                            name="otp"
                                            type="number"
                                            placeholder="Niet gevonden? Kijk even tussen je reclame"
                                            className="form-control email input-field"
                                        />
                                    </div>

                                    <div className="email-btn-div otp-btn-wrapper">
                                        <button
                                            type="submit"
                                            className="start-btn df-line"
                                        >
                                            <span className="txt-black">
                                                Volgende{" "}
                                                {isLoading && <Spinner />}
                                            </span>
                                        </button>
                                        <div className="float-end">
                                            <p
                                                className="txt-primary resend-btn"
                                                onClick={reSendOtpMail}
                                            >
                                                Opnieuw Verzenden{" "}
                                                {reSendLoding && <Spinner />}
                                            </p>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom-map-div email-end-sec">
                    <div className="bottom-txt-div">
                        <img
                            src="../front/assets/img/boyImage.png"
                            alt=""
                            className="img-fluid map-image"
                        />
                        <p className="txt-botm text-light">
                            MAKE IT, TAKE IT, EAT IT
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default OtpVerificationPage;
